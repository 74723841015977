// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N4FCvAq3a5ahdYoYonAw{width:100%;height:auto;box-shadow:0px 1px 1px rgba(0,0,0,.12);z-index:9999}`, "",{"version":3,"sources":["webpack://./src/layouts/components/Header/Header.module.scss"],"names":[],"mappings":"AAGA,sBACI,UAAA,CACA,WAAA,CACA,sCAAA,CACA,YAAA","sourcesContent":["@import '../../../styles/mixins';\r\n@import '../../../styles/variables';\r\n\r\n.wrapper {\r\n    width: 100%;\r\n    height: auto;\r\n    box-shadow: 0px 1px 1px rgb(0 0 0/ 12%);\r\n    z-index: 9999;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `N4FCvAq3a5ahdYoYonAw`
};
export default ___CSS_LOADER_EXPORT___;
